
import { defineComponent } from "vue";
import { useVerifyEmail } from "@/graphql/user/verify-email";

export default defineComponent({
  name: "VerifyAccount",
  components: {},
  setup() {
    return {
      ...useVerifyEmail(),
    };
  },
});
