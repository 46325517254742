import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-pt-3" }
const _hoisted_2 = { class: "p-mb-0" }
const _hoisted_3 = { style: {"font-size":"small","font-weight":"normal"} }
const _hoisted_4 = {
  style: {"min-height":"100px","font-size":"16px"},
  class: "p-d-flex p-flex-column p-jc-center"
}
const _hoisted_5 = { class: "p-text-bol" }
const _hoisted_6 = { class: "p-inputgroup p-mb-3" }
const _hoisted_7 = { class: "p-d-flex p-justify-between p-align-center" }
const _hoisted_8 = { class: "p-error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, {
    style: {"width":"100%","background":"transparent"},
    class: "card p-shadow-0"
  }, {
    title: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_ProgressBar, {
            key: 0,
            class: "p-col-12",
            mode: "indeterminate",
            style: {"height":"0.5em"}
          }))
        : _createCommentVNode("", true),
      _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.$t("verifyAccount.thanking")), 1),
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t("verifyAccount.check")), 1),
      _createElementVNode("small", _hoisted_3, _toDisplayString(_ctx.user.email), 1),
      _createVNode(_component_Divider)
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.$t("verifyAccount.greeting.title")) + " " + _toDisplayString(_ctx.user.name) + ", ", 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("verifyAccount.greeting.body")), 1)
      ])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_Divider),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_InputNumber, {
          modelValue: _ctx.form.code.$value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.code.$value) = $event)),
          placeholder: _ctx.$t('verifyAccount.placeholder'),
          class: _normalizeClass({ 'p-invalid': _ctx.form.code.$hasError })
        }, null, 8, ["modelValue", "placeholder", "class"]),
        _createVNode(_component_Button, {
          disabled: _ctx.hasError,
          label: _ctx.$t('verifyAccount.button'),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.submit()))
        }, null, 8, ["disabled", "label"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.resentTimes < 3)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              label: _ctx.$t('resetPassword.resent'),
              class: "p-button-warning p-button-rounded",
              onClick: _ctx.resendCode,
              loading: _ctx.loadingResend
            }, null, 8, ["label", "onClick", "loading"]))
          : _createCommentVNode("", true),
        _createElementVNode("small", _hoisted_8, _toDisplayString(_ctx.$t("resetPassword.tentative")) + " : " + _toDisplayString(_ctx.resentTimes) + " / 3 ", 1)
      ])
    ]),
    _: 1
  }))
}