import { MutationVerifyEmailArgs, User } from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { useMutation } from "@vue/apollo-composable";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useValidation } from "vue3-form-validation";
import { CONSTANTS } from "@/graphql/utils/utils";
import { useSendCodePin } from "@/graphql/user/send-code-pin";
import { ref } from "vue";

export type PendingAccount = {
  id: number;
  name: string;
  email: string;
  resentTimes: number;
};

type VerifyEmailData = {
  verifyEmail: User;
};

const VERIFY_EMAIL = gql`
  mutation VerifyEmail($input: VerifyUserInput!) {
    verifyEmail(input: $input) {
      id
    }
  }
`;

export const useVerifyEmail = () => {
  const user: PendingAccount = JSON.parse(
    sessionStorage.getItem(CONSTANTS.pendingAccount)
  );
  const resentTimes = ref<number>(user?.resentTimes || 0);
  //console.log(user);
  const { validateFields, hasError, form } = useValidation({
    code: {
      $value: null,
      $rules: [(m: number) => m.toString(10).length !== 6],
    },
    id: user.id,
  });
  const toast = useToast();
  const { t } = useI18n();
  const { push } = useRouter();
  const { loading, onError, mutate, onDone } = useMutation<
    VerifyEmailData,
    MutationVerifyEmailArgs
  >(VERIFY_EMAIL, { fetchPolicy: "no-cache" });
  onDone(({ data, errors }) => {
    if (data?.verifyEmail) {
      sessionStorage.removeItem(CONSTANTS.pendingAccount);
      toast.add({
        severity: "success",
        summary: t("verifyAccount.title"),
        detail: t("verifyAccount.success"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
      setTimeout(() => {
        void push(`${localStorage.getItem(CONSTANTS.appSource)}/sign-in`);
      }, 2000);
    } else {
      toast.add({
        severity: "warn",
        summary: t("verifyAccount.title"),
        detail: t("verifyAccount.codeWrong"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    }
  });
  onError(() => {
    toast.add({
      severity: "warn",
      summary: t("signIn.connexion"),
      detail: t("networkError"),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
  });
  function submit() {
    validateFields().then((input) => void mutate({ input }));
  }

  // resending code
  const {
    loading: loadingResend,
    onDone: onDoneResend,
    sendCode,
  } = useSendCodePin();
  onDoneResend(({ data, errors }) => {
    if (errors) {
      toast.add({
        severity: "warn",
        summary: t("verifyAccount.title"),
        detail: t(`forgotPassword.${errors[0].message}`),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    } else if (data) {
      toast.add({
        severity: "success",
        summary: t("verifyAccount.title"),
        detail: t("signUp.success"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
      Object.assign(user, { resentTimes: data.sendCodePin.resentTimes });
      sessionStorage.setItem(CONSTANTS.pendingAccount, JSON.stringify(user));
      resentTimes.value = data.sendCodePin.resentTimes;
    } else {
      toast.add({
        severity: "error",
        summary: t("verifyAccount.title"),
        detail: t("sendMail.error"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    }
  });

  function resendCode() {
    void sendCode({
      input: {
        email: user.email,
        reason: "activation",
      },
    });
  }

  return {
    user,
    validateFields,
    hasError,
    form,
    loading,
    submit,
    resendCode,
    loadingResend,
    resentTimes,
  };
};
